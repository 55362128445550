import Api from "../../../api/Api";
import { toast } from "react-toastify";
import axios from "axios";
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  LOAD_SUCCESS,
} from "./actionTypes";
import { loadApple } from "../../appleid/actions";
import { loadAppleQ } from "../../appleq/actions";
import { loadGoogle } from "../../google/actions";
import { loadFacebook } from "../../facebook/actions";
import { loadInstagram } from "../../instagram/actions";
import { loadSnapchat } from "../../snapchat/actions";
import { loadPlaystation } from "../../playstation/actions";
import { loadAppleSMS } from "../../applesms/actions";
import { loadAppleQr } from "../../appleqr/actions";

export const loginUser = (user, history) => (dispatch, getState) => {
  axios
    .get("https://api.hkj-haitham-tec.com/sanctum/csrf-cookie")
    .then((response) => {
      Api.post("/auth/login", {
        email: user.email,
        password: user.password,
      })
        .then((response) => {
          dispatch(loginSuccess(response.data));
          dispatch(loadApple());
          dispatch(loadAppleQ());
          dispatch(loadGoogle());
          dispatch(loadFacebook());
          dispatch(loadInstagram());
          dispatch(loadSnapchat());
          dispatch(loadPlaystation());
          dispatch(loadAppleSMS());
          dispatch(loadAppleQr());
          history("/dashboard");
          return {
            type: LOGIN_USER,
            payload: response.data,
          };
        })
        .catch((error) => {
          dispatch(apiError(error.response?.data.message));
        });
    });
};

export const loadUser = (user, history) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  Api.post("/auth/user")
    .then((res) => {
      dispatch({
        payload: res.data,
        type: LOAD_SUCCESS,
      });
      dispatch(loadApple());
      dispatch(loadAppleQ());
      dispatch(loadGoogle());
      dispatch(loadFacebook());
      dispatch(loadInstagram());
      dispatch(loadSnapchat());
      dispatch(loadPlaystation());
      dispatch(loadAppleSMS());
      dispatch(loadAppleQr());
      // dispatch(loadNotifications(res.data.notifications));

      // dispatch(loadItems());
      // dispatch(loadOrders());
      // dispatch(loadTax());
    })

    .catch((error) => {
      dispatch(apiError(error.response?.data.message));
    });
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history) => (dispatch, getState) => {
  localStorage.removeItem("token");
  dispatch({
    type: LOGOUT_USER,
    payload: {},
  });
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  Api.post("/auth/logout")
    .then((res) => {
      // history.push("/login");

      return {
        type: LOGOUT_USER_SUCCESS,
        payload: {},
      };
    })
    .catch((err) => {
      dispatch(apiError(err.response?.data.message));
    });
};

// export const logoutUserSuccess = () => {
//   return {
//     type: LOGOUT_USER_SUCCESS,
//     payload: {},
//   };
// };

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  };
};

export const changePasswordUser = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/auth/changepass", {
    oldpassword: data.oldpassword,
    password: data.password,
  });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        // dispatch(loadApple());
        return "Password changed successfully";
      },
    },
    error: {
      render({ data }) {
        // console.log(data);
        return "An error occurred while changing your password";
      },
    },
  });
};
