export const GET_IDS = "GET_IDS";
export const GET_IDS_SUCCESS = "GET_IDS_SUCCESS";
export const GET_IDS_FAIL = "GET_IDS_FAIL";

export const ADD_IDS_SUCCESS = "ADD_IDS_SUCCESS";
export const ADD_IDS_FAIL = "ADD_IDS_FAIL";
export const UPDATE_IDS_SUCCESS = "UPDATE_IDS_SUCCESS";
export const DELETE_IDS_SUCCESS = "DELETE_IDS_SUCCESS";

export const GET_QRS = "GET_QRS";
export const GET_QRS_SUCCESS = "GET_QRS_SUCCESS";
export const GET_QRS_FAIL = "GET_QRS_FAIL";

export const ADD_QRS_SUCCESS = "ADD_QRS_SUCCESS";
export const ADD_QRS_FAIL = "ADD_QRS_FAIL";
export const UPDATE_QRS_SUCCESS = "UPDATE_QRS_SUCCESS";
export const DELETE_QRS_SUCCESS = "DELETE_QRS_SUCCESS";